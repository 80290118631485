<template>

  <div class="container">
    <p>
      <span>
        [ <router-link :to="{name: 'Applications'}" class="custom-fg-dark">Return to Application Listing</router-link> ]
      </span>
    </p>

    <div class="card mt-4" id="results">

      <div class="card-header">
        <div class="row">
          <div class="col-4">
            <h5>Margin Administration</h5>
          </div>
          <div class="col-4">
            <input id="filter" placeholder="Filter by Loan Purpose" type="text" class="form-control" v-model="searchTerm">
          </div>
          <div v-if="isAdmin" class="col-4" style="text-align:right;">
            <div class="col-auto custom-cursor-pointer mt-2" @click="addMargin()">
              <i class="fa fa-plus-circle customer-fg-dark mr-2" aria-hidden="true" />
              <span>Add Margin</span>
            </div>
          </div>
        </div>
      </div>
      <table class="table table-sm table-hover mb-0">
        <thead class="custom-bg-med custom-sticky-header sticky-top">
          <tr>
            <th scope="col">Loan Purpose</th>
            <th scope="col">Loan Amount</th>
            <th scope="col">Rate</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody v-cloak>
          <tr v-for="(margin, index) in filteredResult" :key="margin.id">
            <td>
              <select v-if="isAdmin" class="custom-select custom-select-sm" id="loanPurpose" v-model="margins[index].loanPurpose" @change="changeLoanPurpose(index)">
                <option
                  v-for="(loanPurpose) in loanPurposes"
                  :key="loanPurpose.id"
                  :value="loanPurpose.name"
                >{{loanPurpose.name}}
                </option>
              </select>
              <label v-if="!isAdmin" class="custom-fg-dark">{{margin.loanPurpose}}</label>
            </td>
            <td class="text-right">
              <CurrencyInputField v-if="isAdmin" :passedData="{name: 'loanAmount', index: index, value: margin.loanAmount}" @input="changeMargin"></CurrencyInputField>
              <label v-if="!isAdmin" class="custom-fg-dark">{{formatCurrency(margin.loanAmount)}}</label>
            </td>
            <td class="text-right">
              <PercentageInputField v-if="isAdmin" :passedData="{name: 'rate', index: index, value: margin.rate}" @input="changeMargin"></PercentageInputField>
              <label v-if="!isAdmin" class="custom-fg-dark">{{margin.rate}}</label>
            </td>
            <td class="text-right">
              <i v-if="isAdmin" class="fa fa-trash" aria-hidden="true" @click="deleteMargin(margin)" />
            </td>
          </tr>
        </tbody>
      </table>

    </div>

  </div>

</template>

<script>
import lodash from 'lodash'
import numeral from 'numeral'

import Store from '@/store'

import Margin from '@/services/MarginService'
import PickDetail from '@/services/PickDetailService'

import CurrencyInputField from '@/components/util/CurrencyInputField'
import PercentageInputField from '@/components/util/PercentageInputField'

export default {
  name: 'margins',
  components: {
    CurrencyInputField,
    PercentageInputField
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { name: 'robots', content: 'noindex, nofollow' }
      ]
    }
  },
  computed: {
    isAdmin: function() {
      return (Store.state.userInfo != null) ? Store.state.userInfo.admin : false;
    },
    filteredResult: function() {
      return this.margins.filter(result => {
        return (
          result.loanPurpose.toLowerCase().match(this.searchTerm.toLowerCase())
        );
      });
    },
  },
  created: async function () {
    this.loanPurposes = await PickDetail.getPickListDetail('loanpurpose');
    this.margins = await Margin.getMargins();
  },
  data: function () {
    return {
      loanPurposes: [],
      margins: [],
      searchTerm: "",
    }
  },
  methods: {
    addMargin: async function() {
      var margin = {loanPurpose: 'Purchase', loanAmount: 0, rate: 0}

      margin = await Margin.createMargin(margin);
      this.margins.push(margin);
    },
    changeLoanPurpose: async function(index) {
      this.margins[index] = await Margin.updateMargin(this.margins[index]);
    },
    changeMargin: async function(returnData) {
      let name = returnData.name;
      let idx = returnData.index;
      let margin = this.margins[idx];
      let newValue = returnData.newValue;

      margin[name] = newValue;
      this.margins[idx] = await Margin.updateMargin(margin);
    },
    deleteMargin: function(margin) {
      Margin.deleteMargin(margin.id);
      this.margins = lodash.without(this.margins, margin);
    },
    formatCurrency: function (amount) {
      return numeral(amount).format('$0,0');
    }
  }
}
</script>
