<template>
  <div>
    <input
      type="text"
      style="text-align:right;"
      class="form-control form-control-sm"
      :id="passedData.name + '_' + passedData.index"
      placeholder="$"
      v-model="newValue"
      @keyup="formatCurrency()"
      @change="changeValue()">
  </div>
</template>

<script>
import numeral from 'numeral';

export default {
  name: 'currencyInputField',
  created: async function () {
    this.formatCurrency();
  },
  data: function () {
    return {
      newValue: this.passedData.value
    }
  },
  methods: {
    changeValue: function() {
      if (this.convertString(this.passedData.value) != this.convertString(this.newValue)) {
        var returnData = {
          name: this.passedData.name,
          index: this.passedData.index,
          newValue: this.convertString(this.newValue),
          value: this.passedData.value
        };

        this.$emit("input", returnData);
      }
    },
    convertString: function (val) {
      if (val == null) {
        val = 0.0;
      } else if (typeof val === 'string') {
        val = val.replace(/\D/g, '');

        if (val != "") {
          val = (+val);
        } else {
          val = 0.0;
        }
      }

      return val;
    },
    formatCurrency: function () {
      this.newValue = numeral(this.convertString(this.newValue)).format('$0,0');
    },
  },
  props: ['passedData'],
}
</script>
