import HTTP from './HttpService'

var Margin = {

  createMargin: async function (marginData) {
    let dividedRate = marginData.rate / 100;

    marginData.rate = parseFloat(dividedRate.toFixed(4));

    let responseMargin = {};

    await HTTP.http().post('/margin/create', marginData)
      .then(function(result) {
        responseMargin = result.data;
      })
      .catch(function(error) {
        console.log('Create margin error: ' + error);
      });

    return responseMargin;
  },

  deleteMargin: async function (marginId) {
    let deleteResponse = {};

    await HTTP.http().delete('/margin/delete/' + marginId)
      .then(function(result) {
        deleteResponse = result.data;
      })
      .catch(function(error) {
        console.log('Delete margin error: ' + error);
      });

    return deleteResponse;
  },

  getMargins: async function () {
    let margins = [];

    await HTTP.http().get('/margin')
      .then(function(response) {
        if (response) {
          margins = response.data;
        }
      })
      .catch(function(error) {
        console.log('Load margins error: ' + error);
      });

    return margins;
  },

  updateMargin: async function (marginData) {
    let dividedRate = marginData.rate / 100;

    marginData.rate = parseFloat(dividedRate.toFixed(4));

    let responseMargin = {};

    await HTTP.http().post('/margin/update', marginData)
      .then(function(result) {
        responseMargin = result.data;
      })
      .catch(function(error) {
        console.log('Update margin error: ' + error);
      });

      return responseMargin;
  }

}

export default Margin;
