<template>
  <div>
    <input
      type="text"
      style="text-align:right;"
      class="form-control form-control-sm"
      :id="passedData.name + '_' + passedData.index"
      placeholder="%"
      v-model="newValue"
      v-on:click="clear"
      @keypress="isAvailable($event)"
      @change="changeValue()">
  </div>
</template>

<script>

export default {
  name: 'percentageInputField',
  created: async function () {
    let multipliedValue = this.newValue * 100;

    this.newValue = parseFloat(multipliedValue.toFixed(2))

    this.formatPercentage();
  },
  data: function () {
    return {
      newValue: this.passedData.value
    }
  },
  methods: {
    changeValue: function() {
      if (this.convertString(this.passedData.value) !== this.convertString(this.newValue)) {

        let returnData = {
          name: this.passedData.name,
          index: this.passedData.index,
          newValue: this.newValue,
          value: this.passedData.value
        };

        this.$emit("input", returnData);

        this.formatPercentage();
      }
    },
    convertString: function (val) {
      if (val == null) {
        val = 0.0;
      } else if (typeof val === 'string') {
        val = val.replace(/[A-Za-z%]/g, '');

        if (val !== "") {
          val = (+val);
        } else {
          val = 0.0;
        }
      }

      return val;
    },
    formatPercentage: function () {
      this.newValue = parseFloat(this.newValue).toFixed(2) + "%";
    },
    clear: function () {
      this.newValue = null;
    },
    isAvailable: function(event) {
      let charCode = (event.which) ? event.which : event.keyCode;

      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        event.preventDefault();
      } else {

        let decPart = (this.newValue + "").split(".")[1];

        if (decPart.length >= 2) {
          event.preventDefault();
        }

        return true;
      }
    }
  },
  props: ['passedData'],
}
</script>
