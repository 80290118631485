import HTTP from './HttpService'

var PickDetail = {

  createPickDetail: async function (pickDetailData) {
    var responsePickDetail = {};

    await HTTP.http().post('/pickdetail/create', pickDetailData)
      .then(function(result) {
        responsePickDetail = result.data;
      })
      .catch(function(error) {
        console.log('Create pickDetail error: ' + error);
      });

    return responsePickDetail;
  },

  deletePickDetail: async function (pickDetailId) {
    var deleteResponse = {};

    await HTTP.http().delete('/pickdetail/delete/' + pickDetailId)
      .then(function(result) {
        deleteResponse = result.data;
      })
      .catch(function(error) {
        console.log('Delete pickDetail error: ' + error);
      });

    return deleteResponse;
  },

  getPickListDetail: async function(lookupKey) {
    var pickDetail = [];

    await HTTP.http().get('/' + lookupKey)
      .then(function(response) {
        if (response) {
          pickDetail = response.data;
        }
      })
      .catch(function(error) {
        console.log('Load error for lookup key "' + lookupKey + '": ' + error);
      });

      return pickDetail;
  },

  getPickListDetailByPickListId: async function(pickListId) {
    var pickDetail = [];

    await HTTP.http().get('/pickdetail/picklistid/' + pickListId)
      .then(function(response) {
        if (response) {
          pickDetail = response.data;
        }
      })
      .catch(function(error) {
        console.log('Load error for pick list id "' + pickListId + '": ' + error);
      });

      return pickDetail;
  },

  updatePickDetail: async function (pickDetailData) {
    var responsePickDetail = {};

    await HTTP.http().post('/pickdetail/update', pickDetailData)
      .then(function(result) {
        responsePickDetail = result.data;
      })
      .catch(function(error) {
        console.log('Update pickDetail error: ' + error);
      });

      return responsePickDetail;
  }

}

export default PickDetail;
